<template>
    <popup-modal ref="popup">
        <h2 style="margin-top: 0">{{ title }}</h2>
        <p>{{ message }}</p>
        <form class="form">
          <div class="input-group">
            <label for="email">Epost</label>
            <input type="email" class="input" name="email" v-model="form.email" />
          </div>
          <div class="input-group">
            <label for="name">Navn</label>
            <input type="text" class="input" name="name" v-model="form.name" />
          </div>
          <div class="input-group">
            <label for="role">Velg rolle</label>
            <select v-model="form.role" name="role" id="role">
              <option value="2">Administrator</option>
              <option value="3">Bruker</option>
            </select>
          </div>
        </form>
        <div class="btns">
            <button class="btn cancel-btn" @click="cancel">{{ cancelButton }}</button>
            <span class="btn send-btn" @click="confirm">{{ okButton }}</span>
        </div>
    </popup-modal>
</template>
<script>
import PopupModal from './PopupModal.vue'

export default {
    name: 'AddUserDialog',
    components: { PopupModal },
    data: () => ({
        title: undefined,
        message: undefined,
        okButton: undefined,
        cancelButton: 'Avbryt',
        resolvePromise: undefined,
        rejectPromise: undefined,
        form: {
          name: '',
          role: '2',
          email: ''
        }
    }),

    methods: {
        show(opts = {}) {
            this.title = opts.title
            this.message = opts.message
            this.okButton = opts.okButton
            if (opts.cancelButton) {
                this.cancelButton = opts.cancelButton
            }
            this.$refs.popup.open()

            return new Promise((resolve, reject) => {
              this.resolvePromise = resolve
              this.rejectPromise = reject
              this.clearForm();
            })
        },
        confirm() {
          this.$refs.popup.close()
          this.resolvePromise(this.form)
        },
        cancel() {
          this.$refs.popup.close()
          this.resolvePromise(false)
          // this.clearForm();
        },
        clearForm() {
          this.form.name = '';
          this.form.email = '';
          this.form.role = '2';
        }
    },
}
</script>
<style lang="scss" scoped>
  p {
    margin-bottom: 2rem;
  }
  .form {
    input:focus {
      border: 1px solid $blue;
    }
  }
  .btns {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .btn {
    font-size: 13px;
    font-weight: 600;
    border-radius: 12px;
    padding: 0.75rem 1rem;
    color: white;
    text-decoration: none;
    cursor: pointer;
  }
  .send-btn {
    background-color: $hytta;
    &:hover {
      background-color: darken($hytta, 5%)
    }
  }
  .cancel-btn {
    background-color: $medium-grey;
    color: black;
    &:hover {
      background-color: darken($medium-grey, 5%)
    }
  }
</style>
