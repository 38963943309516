<template>
  <CabinHero v-if="!isMobile()" :cabin="cabin" :cabinUserRoleId="cabinUserRoleId"></CabinHero>
  <section class="cabin-settings">
    <row container :gutter="20">
      <column :xs="12" :md="6" :lg="6">
        <form @submit.prevent="updateCabin()">
          <div class="input-group">
            <label for="name">Hyttenavn</label>
            <input type="text" class="input" :disabled="noEdit" name="name" v-model="form.name" />
          </div>
          <CabinImage v-if="isMobile()" :cabin="cabin" :cabinUserRoleId="cabinUserRoleId"></CabinImage>
          <div class="input-group">
            <label for="name">Søk etter lokasjon</label>
            <GMapAutocomplete :disabled="noEdit" placeholder="Skriv inn adressen..." @place_changed="setPlace"></GMapAutocomplete>
          </div>
          <div class="input-group">
            <label for="name">Adresse</label>
            <input type="text" :disabled="noEdit" class="input" name="name" v-model="form.address" />
          </div>
          <!-- <div class="input-group">
                  <label for="name">Postnummer</label>
                  <input
                      type="text"
                      class="input"
                      name="name"
                      v-model="form.postcode"
                  >
          </div>-->
          <div class="map-container">
            <GMapMap
              :center="currentLocation"
              :zoom="12"
              :options="{
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUi: true
              }"
              map-type-id="roadmap"
              style="width: 100%; height: 100%"
            >
              <GMapMarker
                :key="index"
                v-for="(marker, index) in markers"
                :position="marker.position"
              />
            </GMapMap>
          </div>
          <Button v-show="!noEdit" type="submit" text="Oppdater hytte" color="green" :disabled="disabled"></Button>
        </form>
        <div v-show="!noEdit" class="delete_actions">
          <Button text="Slett hytta" color="red" @click.prevent="deleteCabin()"></Button>
          <ConfirmationDialog ref="deleteCabin"></ConfirmationDialog>
        </div>
      </column>
      <column :xs="12" :md="6" :lg="6">
        <div class="input-group" v-if="finishedLoading">
          <label for="name">Brukere</label>
          <UserCard
            v-for="(item, index) in cabinUsers"
            :key="index"
            :user="item"
            :invite="false"
            @click="editUser(item)"
          >
          </UserCard>
          <EditUserDialog ref="editUserDialog"></EditUserDialog>
        </div>
        <div class="input-group" v-if="editedCabin.cabinUserInvites.length > 0">
          <label for="name">Invitasjoner</label>
          <UserCard
            v-for="(item, index) in editedCabin.cabinUserInvites"
            :key="index"
            :user="item"
            :invite="true"
            @click="deleteUserInvite(item)"
          >
          </UserCard>
        </div>
        <div v-if="!noEdit" class="input-group">
          <div @click="inviteUser()" class="useradd">
            <div class="useradd-icon">
              <fa icon="plus" />
            </div>
            <div class="useradd-text">
              Legg til ny bruker
            </div>
          </div>
        </div>
        <AddUserDialog ref="addUserDialog"></AddUserDialog>
        <Button text="Forlat hytta" color="orange" @click.prevent="leaveCabin()"></Button>
        <ConfirmationDialog ref="leaveCabin"></ConfirmationDialog>
        <ConfirmationDialog ref="deleteUserInviteDialog"></ConfirmationDialog>
      </column>
    </row>
  </section>
</template>
<script>
import CabinService from '@/services/CabinService';
import UserService from '@/services/UserService';
import CabinImage from '@/components/CabinImage';
import CabinHero from '@/components/CabinHero';
import Button from '@/components/Button';
import ConfirmationDialog from '@/components/modals/ConfirmationDialog';
import AddUserDialog from '@/components/modals/AddUserDialog';
import EditUserDialog from '@/components/modals/EditUserDialog'
import UserCard from '@/components/UserCard';
import { mapGetters } from 'vuex';
import Helpers from '@/helpers/helpers';

export default {
  components: {
    Button,
    ConfirmationDialog,
    AddUserDialog,
    EditUserDialog,
    UserCard,
    CabinImage,
    CabinHero
  },
  props: {
    cabinUserRoleId: Number,
    cabin: Object
  },
  data() {
    return {
      editedCabin: undefined,
      form: {
        name: '',
        address: '',
        postcode: '',
        latitude: 59.91333,
        longitude: 10.73897
      },
      disabled: false,
      cabinUserInfo: [],
      finishedLoading: false
    };
  },
  computed: {
    ...mapGetters(['users/userId']),
    currentLocation() {
      return {
        lat: this.form.latitude,
        lng: this.form.longitude
      };
    },
    noEdit() {
      if(this.isCabinOwner() || this.isCabinAdministrator()) return false;

      return true;
    },
    markers() {
      return [
        {
          position: {
            lat: this.form.latitude,
            lng: this.form.longitude
          }
        }
      ];
    },
    cabinUsers() {
      let cabinUsers = []
      this.cabinUserInfo.forEach((itemA) => {
        let index = this.editedCabin.cabinUserRoles.findIndex((itemB) => {
          return itemB.cabinUserId === itemA.id;
        })
        cabinUsers.push({
          id: this.editedCabin.cabinUserRoles[index].id,
          firstName: itemA.firstName,
          lastName: itemA.lastName,
          cabinRoleId: this.editedCabin.cabinUserRoles[index].cabinRoleId
        });
      })
      return cabinUsers;
    }
  },
  created() {
    this.editedCabin = JSON.parse(JSON.stringify(this.cabin))
    this.getCabinUserInfo();
    this.form.name = this.cabin.name;
    this.form.address = this.cabin.address;
    this.form.postcode = this.cabin.postcode;
    if (this.cabin.latitude && this.cabin.longitude) {
      this.form.latitude = this.cabin.latitude;
      this.form.longitude = this.cabin.longitude;
    }
  },
  methods: {
    isMobile: Helpers.isMobile,
    getCabinUserInfo() {
      let cabinUserRoleIds = this.editedCabin.cabinUserRoles.map((item) => {
        return item.cabinUserId;
      })
      UserService.getUserInfoFromB2CAsync(cabinUserRoleIds).then(res => {
        this.cabinUserInfo = res.data;
        this.finishedLoading = true;
      }).catch(err => {
        console.log(err);
      })
    },
    updateCabin() {
      this.disabled = true;
      let requestData = {
        cabinId: this.cabin.id,
        name: this.form.name,
        address: this.form.address,
        postalCode: this.form.postcode,
        latitude: this.form.longitude,
        longitude: this.form.latitude
      };
      CabinService.updateCabin(...Object.values(requestData))
        .then(res => {
          console.log('Successfully updated Cabin', res);
          this.$router.push({ name: 'calendar' });
          this.$toast.success(`Hytte oppdatert`);
        })
        .catch(error => {
          this.$toast.error(`Kunne ikke oppdatere hytte`);
          console.log(error);
          this.disabled = false;
        });
    },
    async deleteCabin() {
      const confirmDelete = await this.$refs.deleteCabin.show({
        title: 'Slett hytta',
        message: 'Er du sikker på du vil slette denne hytten?',
        okButton: 'Slett hytta',
      })
      if (confirmDelete) {
        CabinService.deleteCabin(this.cabin.id).then(() => {
          this.$router.push({ name: 'Cabins' });
          this.$toast.success(`Hytten ble slettet`);
        })
      } else {
        this.$toast.info(`Sletting avbrutt`);
      }
    },
    async leaveCabin() {
      const confirmLeave = await this.$refs.leaveCabin.show({
        title: 'Forlat hytta',
        message: 'Er du sikker på at du vil forlate denne hytta?',
        okButton: 'Forlat hytta',
      })
      if(confirmLeave) {
        let userRoleId = false;
        for(let i = 0; i < this.cabin.cabinUserRoles.length; i++) {
          let item = this.cabin.cabinUserRoles[i];
          if(this['users/userId'] === item.cabinUserId) {
            userRoleId = item.id;
            break;
          }
        }
        if(userRoleId) {
          CabinService.deleteCabinUserRole(userRoleId).then((res) => {
            console.log(res);
            this.$router.push({ name: 'Cabins' });
          }).catch(err => {
            console.log(err);
            console.log('Kunne ikke fjerne deg fra denne hytta');
          })
        } else {
          this.$toast.info(`Kunne ikke fjerne deg fra denne hytta`);
        }
      }
    },
    async editUser(user) {
      if(user.cabinRoleId === 1 || !this.isCabinOwner()) return;
      const confirmEdit = await this.$refs.editUserDialog.show({
        title: 'Rediger bruker',
        message: `Du redigerer brukeren til ${user.firstName} ${user.lastName} på denne hytten`,
        okButton: 'Lagre',
        cancelButton: 'Avbryt',
        deleteButton: 'Fjern bruker',
        user: user
      })
      if(confirmEdit) {
        if(confirmEdit.shouldDelete) {
          CabinService.deleteCabinUserRole(user.id).then(res => {
            console.log(res);
            const index = this.editedCabin.cabinUserRoles.findIndex(item => {
              return item.id === user.id;
            })
            if(index !== -1) {
              this.editedCabin.cabinUserRoles.splice(index, 1);
              this.getCabinUserInfo();
              this.$toast.success(`Bruker fjernet`);
            } else {
              throw new Error()
            }
          }).catch(err => {
            console.log('Could not delete user from cabin', err);
            this.$toast.error(`Kunne ikke fjerne bruker`);
          })
        } else if(confirmEdit.role !== user.cabinRoleId) {
          CabinService.updateCabinUserRole(user.id, parseInt(confirmEdit.role)).then(res => {
            console.log(res);
            let item = this.editedCabin.cabinUserRoles.find(item => {
              return item.id === user.id;
            })
            let index = this.editedCabin.cabinUserRoles.findIndex(item => {
              return item.id === user.id;
            })
            item.cabinRoleId = parseInt(confirmEdit.role);
            this.editedCabin.cabinUserRoles.splice(index, 1, item);
            this.$toast.success(`Oppdaterte bruker`);
          }).catch(err => {
            console.log(err);
            this.$toast.error(`Kunne ikke oppdatere bruker`);
          });
        } else {
          this.$toast.info(`Brukerredigering avbrutt`);
        }
      }
    },
    async inviteUser() {
      const confirmInviteUser = await this.$refs.addUserDialog.show({
        title: 'Legg til ny bruker',
        message: 'Skriv inn epost, navn og velg rolle for å legge til en ny bruker',
        okButton: 'Send invitasjon'
      });
      if(confirmInviteUser) {
        CabinService.addCabinUserInvite(
          confirmInviteUser.name,
          confirmInviteUser.email,
          this.cabin.id,
          parseInt(confirmInviteUser.role)
        ).then(res => {
          if(res.status !== 200) {
            throw new Error(res.status)
          } else {
            console.log(res);
            this.editedCabin.cabinUserInvites.push(res.data);
            this.$toast.success(`Bruker invitert`);
          }
        }).catch(err => {
          this.$toast.error(`Kunne ikke invitere bruker`);
          console.log(err);
        })
      } else {
        this.$toast.info(`Brukerinvitasjon avbrutt`);
      }
    },
    async deleteUserInvite(invite) {
      if(!this.isCabinOwner() && !this.isCabinAdministrator()) return;
      const confirmDeleteUserInvite = await this.$refs.deleteUserInviteDialog.show({
        title: 'Slett invitasjon',
        message: 'Er du sikker på at du vil slette denne invitasjonen?',
        okButton: 'Slett invitasjon'
      });
      if(confirmDeleteUserInvite) {
        CabinService.deleteCabinUserInvite(invite.id).then(res => {
          if(res.status !== 200) {
            throw new Error(res.status)
          } else {
            console.log(res);
            let index = this.editedCabin.cabinUserInvites.findIndex(item => {
              return item.id === invite.id;
            });
            this.editedCabin.cabinUserInvites.splice(index, 1);
            this.$toast.success(`Invitasjon slettet`);
          }
        }).catch(err => {
          this.$toast.error(`Kunne ikke slette invitasjonen`);
          console.log(err);
        })
      } else {
        this.$toast.info(`Sletting avbrutt`);
      }
    },
    setPlace(val) {
      this.form.address = val.formatted_address;
      this.form.latitude = val.geometry.location.lat();
      this.form.longitude = val.geometry.location.lng();
    },
    isCabinOwner() {
      if (this.cabinUserRoleId === 1) return true;

      return false;
    },
    isCabinAdministrator() {
      if (this.cabinUserRoleId === 2) return true;

      return false;
    },
    isCabinUser() {
      if (this.cabinUserRoleId === 3) return true;

      return false;
    },
  }
};
</script>
<style lang="scss" scoped>
.cabin-settings {
  @include gutter-padding;
  @media (max-width: $breakpoint-mobile) {
    margin-bottom: 6rem;
  }
  @media (min-width: $breakpoint-mobile) {
    margin: 4rem 0;
  }
  .pac-target-input {
    background-color: $hytta;
    color: white;
  }
  .pac-target-input::placeholder {
    color: white;
  }
  .map-container {
    margin-top: 1.5rem;
  }
  .button {
    width: 100%;
  }
  .cabin_actions .button {
    margin-bottom: 1rem;
  }
  .delete_actions {
    margin-top: 1rem;
  }
  .useradd {
    background-color: $medium-grey;
    box-sizing: border-box;
    border: 0;
    width: 100%;
    padding: .75rem;
    filter: drop-shadow(0 10px 20px rgba(0, 0, 0, 0.1));
    border-radius: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &-icon {
      padding: .5rem;
      border: 0;
      border-radius: 8px;
      color: black;
      background-color: white;
      font-weight: 400;
      margin-right: .5rem;
      font-size: 1.2rem;
    }
    &-text {
      font-weight: 400;
    }
  }
}
</style>
