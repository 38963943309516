<template>
  <div class="usercard" :class="{ 'usercard-invite': invite }">
    <div class="usercard-initials">
      {{ initials }}
    </div>
    <div class="usercard-name">
      {{ name }}
    </div>
    <div class="usercard-role">
      {{ role.name }}
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'UserCard',
  props: {
    user: Object,
    invite: Boolean
  },
  computed: {
    ...mapGetters([
      'utilities/cabinRoles',
    ]),
    name() {
      if(this.invite) {
        return `${this.user.firstName} (${this.user.email})`
      }

      return `${this.user.firstName} ${this.user.lastName}`;
    },
    initials() {
      return `${this.user.firstName.substr(0, 1)}`;
    },
    role() {
      return this['utilities/cabinRoles'].find((item) => {
        return item.id === this.user.cabinRoleId;
      });
    }
  }
}
</script>
<style lang="scss" scoped>
  .usercard {
    background-color: white;
    box-sizing: border-box;
    border: 0;
    width: 100%;
    padding: .75rem;
    filter: drop-shadow(0 10px 20px rgba(0, 0, 0, 0.1));
    border-radius: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &-initials {
      padding: .5rem .75rem;
      border: 0;
      border-radius: 8px;
      color: white;
      background-color: $hytta;
      font-weight: 500;
      margin-right: .5rem;
      font-weight: 1.5rem;
    }
    &-name {
      font-weight: 500;
    }
    &-role {
      margin-left: auto;
      font-size: 12px;
      background-color: #C1FFF0;
      color: $hytta;
      padding: .35rem;
      border: 0;
      border-radius: 10px;
    }
  }
  .usercard:not(:last-child) {
    margin-bottom: 1rem;
  }
  .usercard-invite {
    .usercard-initials {
      background-color: $warmth;
    }
  }
</style>
